import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import i18n from './i18n'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

Vue.config.productionTip = false

Sentry.init({
  Vue,
  dsn: 'https://75d47847279b49ecbceae16842e1e52c@o1423856.ingest.sentry.io/6771614',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router)
    })
  ],
  tracesSampleRate: 1.0
})

new Vue({
  router,
  vuetify,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
