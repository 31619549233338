import { getSdk, IamQuery, SdkFunctionWrapper } from '@/generated/graphql'
import { GraphQLClient } from 'graphql-request'
import * as Sentry from '@sentry/vue'

const client = new GraphQLClient(
  process.env.NODE_ENV === 'production'
    ? 'https://server.oxy.llc/graphql'
    : '/graphql',
  {
    headers () {
      const token = localStorage.getItem('token')
      return new Headers({
        Authorization: token ? `Bearer ${localStorage.getItem('token')}` : ''
      })
    }
  }
)

export const signOut = () => {
  localStorage.clear()
  location.reload()
}

const wrapper: SdkFunctionWrapper = async (action, operationName) => {
  try {
    const res = await action()
    if (operationName === 'iam' && !(res as IamQuery).user) {
      throw new Error('unauthorized')
    }

    if (operationName === 'iam') {
      Sentry.setContext('user', {
        id: (res as IamQuery).user?.id || 'unauthorized'
      })
    }

    return res
  } catch (error: any) {
    console.error('GraphQLError', error)
    Sentry.captureException(error)

    // signOut()
    throw error
  }
}

export const sdk = getSdk(client, wrapper)
