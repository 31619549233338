import { GraphQLClient } from 'graphql-request'
import * as Dom from 'graphql-request/dist/types.dom'
import gql from 'graphql-tag'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any
  Json: any
  /** Overall statistics */
  Overall: any
  /** PromoTurnover statistics */
  PromoTurnover: any
  /** Transactions sum per day */
  Timeseries: any
  TransactionDescr: any
  WalletData: any
}

export type Asset = {
  __typename?: 'Asset'
  download?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  upload?: Maybe<Scalars['String']>
}

export enum Balance {
  Bonus = 'bonus',
  General = 'general',
  Plan = 'plan',
  Promo = 'promo',
  Reward = 'reward',
  Withdraw = 'withdraw',
}

export type BitcoinPayment = {
  __typename?: 'BitcoinPayment'
  btc?: Maybe<Scalars['Float']>
  rate?: Maybe<Scalars['Float']>
  status: BitcoinPaymentStatus
  txId: Scalars['String']
  usd?: Maybe<Scalars['Float']>
}

export enum BitcoinPaymentStatus {
  AlreadyClaimed = 'ALREADY_CLAIMED',
  Confirmed = 'CONFIRMED',
  NotFound = 'NOT_FOUND',
}

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>
  not?: InputMaybe<NestedBoolFilter>
}

export type CheckCredentials = {
  __typename?: 'CheckCredentials'
  isVerified?: Maybe<Scalars['Boolean']>
  twoFactor?: Maybe<Scalars['Boolean']>
}

export enum Coin {
  UsdtTron = 'USDT_TRON',
}

export type Dashboard = {
  __typename?: 'Dashboard'
  firstLevelSumTS?: Maybe<Scalars['Timeseries']>
  l1Turnover?: Maybe<Scalars['Int']>
  networkCount?: Maybe<Scalars['Int']>
  overall?: Maybe<Scalars['Overall']>
  overallTS?: Maybe<Scalars['Timeseries']>
  promoTurnover?: Maybe<Scalars['PromoTurnover']>
  strongestBranchTS?: Maybe<Scalars['Timeseries']>
  sumOfOthersBranchesTS?: Maybe<Scalars['Timeseries']>
  userId?: Maybe<Scalars['Int']>
}

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>
  gt?: InputMaybe<Scalars['DateTime']>
  gte?: InputMaybe<Scalars['DateTime']>
  in?: InputMaybe<Array<Scalars['DateTime']>>
  lt?: InputMaybe<Scalars['DateTime']>
  lte?: InputMaybe<Scalars['DateTime']>
  not?: InputMaybe<NestedDateTimeFilter>
  notIn?: InputMaybe<Array<Scalars['DateTime']>>
}

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>
  gt?: InputMaybe<Scalars['DateTime']>
  gte?: InputMaybe<Scalars['DateTime']>
  in?: InputMaybe<Array<Scalars['DateTime']>>
  lt?: InputMaybe<Scalars['DateTime']>
  lte?: InputMaybe<Scalars['DateTime']>
  not?: InputMaybe<NestedDateTimeNullableFilter>
  notIn?: InputMaybe<Array<Scalars['DateTime']>>
}

export type EnumBalanceFilter = {
  equals?: InputMaybe<Balance>
  in?: InputMaybe<Array<Balance>>
  not?: InputMaybe<NestedEnumBalanceFilter>
  notIn?: InputMaybe<Array<Balance>>
}

export type EnumPlanNullableFilter = {
  equals?: InputMaybe<Plan>
  in?: InputMaybe<Array<Plan>>
  not?: InputMaybe<NestedEnumPlanNullableFilter>
  notIn?: InputMaybe<Array<Plan>>
}

export type EnumTransactionKindFilter = {
  equals?: InputMaybe<TransactionKind>
  in?: InputMaybe<Array<TransactionKind>>
  not?: InputMaybe<NestedEnumTransactionKindFilter>
  notIn?: InputMaybe<Array<TransactionKind>>
}

export type FloatFilter = {
  equals?: InputMaybe<Scalars['Float']>
  gt?: InputMaybe<Scalars['Float']>
  gte?: InputMaybe<Scalars['Float']>
  in?: InputMaybe<Array<Scalars['Float']>>
  lt?: InputMaybe<Scalars['Float']>
  lte?: InputMaybe<Scalars['Float']>
  not?: InputMaybe<NestedFloatFilter>
  notIn?: InputMaybe<Array<Scalars['Float']>>
}

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']>
  gt?: InputMaybe<Scalars['Int']>
  gte?: InputMaybe<Scalars['Int']>
  in?: InputMaybe<Array<Scalars['Int']>>
  lt?: InputMaybe<Scalars['Int']>
  lte?: InputMaybe<Scalars['Int']>
  not?: InputMaybe<NestedIntFilter>
  notIn?: InputMaybe<Array<Scalars['Int']>>
}

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>
  gt?: InputMaybe<Scalars['Int']>
  gte?: InputMaybe<Scalars['Int']>
  in?: InputMaybe<Array<Scalars['Int']>>
  lt?: InputMaybe<Scalars['Int']>
  lte?: InputMaybe<Scalars['Int']>
  not?: InputMaybe<NestedIntNullableFilter>
  notIn?: InputMaybe<Array<Scalars['Int']>>
}

export type Mutation = {
  __typename?: 'Mutation'
  addTwoFactor?: Maybe<TwoFactor>
  addWallet?: Maybe<Wallet>
  assignCustodial?: Maybe<Wallet>
  assignUSDTWallet?: Maybe<User>
  btcDeposit?: Maybe<Transaction>
  consumePromocode?: Maybe<Promocode>
  createPromocode?: Maybe<Array<Maybe<Promocode>>>
  deleteUserPromo?: Maybe<User>
  deposit?: Maybe<Scalars['String']>
  generateCustodial?: Maybe<Wallet>
  getTransaction?: Maybe<Array<Maybe<Transaction>>>
  hideData?: Maybe<User>
  makeTransaction?: Maybe<Transaction>
  purchase?: Maybe<Purchase>
  resetPassword?: Maybe<Scalars['Boolean']>
  sendVerification?: Maybe<Scalars['Boolean']>
  setPassword?: Maybe<Scalars['Boolean']>
  setTwoFactor?: Maybe<TwoFactor>
  signIn?: Maybe<Scalars['String']>
  signUp?: Maybe<User>
  submitBitcoinPayment?: Maybe<BitcoinPayment>
  transfer?: Maybe<Transaction>
  updateUser?: Maybe<User>
  verifyEmail?: Maybe<Scalars['Boolean']>
  withdrawBTCRequest?: Maybe<Transaction>
  withdrawCodeRequest?: Maybe<Scalars['Boolean']>
  withdrawRequest?: Maybe<Transaction>
}

export type MutationAddWalletArgs = {
  coin: Coin
}

export type MutationAssignCustodialArgs = {
  coin: Coin
  overwrite?: InputMaybe<Scalars['Boolean']>
}

export type MutationAssignUsdtWalletArgs = {
  usdtWallet: Scalars['String']
}

export type MutationBtcDepositArgs = {
  amount: Scalars['Float']
  amountInBTC: Scalars['Float']
  btcWallet: Scalars['String']
  userId: Scalars['Int']
}

export type MutationConsumePromocodeArgs = {
  id: Scalars['ID']
}

export type MutationCreatePromocodeArgs = {
  amount: Scalars['Float']
  count?: Scalars['Int']
}

export type MutationDeleteUserPromoArgs = {
  id: Scalars['Int']
}

export type MutationGenerateCustodialArgs = {
  coin: Coin
  count?: Scalars['Int']
  feeLimit?: Scalars['Float']
}

export type MutationGetTransactionArgs = {
  kind?: InputMaybe<Scalars['String']>
  skip: Scalars['Int']
  take: Scalars['Int']
}

export type MutationHideDataArgs = {
  hideData: Scalars['Boolean']
  id?: InputMaybe<Scalars['Int']>
}

export type MutationMakeTransactionArgs = {
  amount: Scalars['Float']
  balance: Balance
  descr?: InputMaybe<Scalars['TransactionDescr']>
  kind: TransactionKind
  userId: Scalars['Int']
}

export type MutationPurchaseArgs = {
  fromPromo?: InputMaybe<Scalars['Boolean']>
  plan: Plan
  topUp: Scalars['Int']
}

export type MutationResetPasswordArgs = {
  callbackUrl: Scalars['String']
  email: Scalars['String']
}

export type MutationSendVerificationArgs = {
  callbackUrl: Scalars['String']
  email: Scalars['String']
}

export type MutationSetPasswordArgs = {
  password: Scalars['String']
  token: Scalars['String']
}

export type MutationSetTwoFactorArgs = {
  enable?: InputMaybe<Scalars['Boolean']>
}

export type MutationSignInArgs = {
  emailOrPhoneNumber: Scalars['String']
  password: Scalars['String']
  token?: InputMaybe<Scalars['String']>
}

export type MutationSignUpArgs = {
  callbackUrl?: InputMaybe<Scalars['String']>
  email: Scalars['String']
  fullName: Scalars['String']
  password: Scalars['String']
  phoneNumber: Scalars['String']
  refererId?: InputMaybe<Scalars['Int']>
  telegramId?: InputMaybe<Scalars['String']>
}

export type MutationSubmitBitcoinPaymentArgs = {
  txId: Scalars['String']
}

export type MutationTransferArgs = {
  amount: Scalars['Float']
  from: Balance
  to: Balance
}

export type MutationUpdateUserArgs = {
  id?: InputMaybe<Scalars['Int']>
  oldPassword?: InputMaybe<Scalars['String']>
  password?: InputMaybe<Scalars['String']>
}

export type MutationVerifyEmailArgs = {
  verificationToken: Scalars['String']
}

export type MutationWithdrawBtcRequestArgs = {
  amount: Scalars['Float']
  amountInBTC: Scalars['Float']
  to: Scalars['String']
  token?: InputMaybe<Scalars['String']>
}

export type MutationWithdrawCodeRequestArgs = {
  code: Scalars['Int']
  email: Scalars['String']
}

export type MutationWithdrawRequestArgs = {
  amount: Scalars['Float']
  to: Scalars['String']
  token?: InputMaybe<Scalars['String']>
}

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>
  not?: InputMaybe<NestedBoolFilter>
}

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>
  gt?: InputMaybe<Scalars['DateTime']>
  gte?: InputMaybe<Scalars['DateTime']>
  in?: InputMaybe<Array<Scalars['DateTime']>>
  lt?: InputMaybe<Scalars['DateTime']>
  lte?: InputMaybe<Scalars['DateTime']>
  not?: InputMaybe<NestedDateTimeFilter>
  notIn?: InputMaybe<Array<Scalars['DateTime']>>
}

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>
  gt?: InputMaybe<Scalars['DateTime']>
  gte?: InputMaybe<Scalars['DateTime']>
  in?: InputMaybe<Array<Scalars['DateTime']>>
  lt?: InputMaybe<Scalars['DateTime']>
  lte?: InputMaybe<Scalars['DateTime']>
  not?: InputMaybe<NestedDateTimeNullableFilter>
  notIn?: InputMaybe<Array<Scalars['DateTime']>>
}

export type NestedEnumBalanceFilter = {
  equals?: InputMaybe<Balance>
  in?: InputMaybe<Array<Balance>>
  not?: InputMaybe<NestedEnumBalanceFilter>
  notIn?: InputMaybe<Array<Balance>>
}

export type NestedEnumPlanNullableFilter = {
  equals?: InputMaybe<Plan>
  in?: InputMaybe<Array<Plan>>
  not?: InputMaybe<NestedEnumPlanNullableFilter>
  notIn?: InputMaybe<Array<Plan>>
}

export type NestedEnumTransactionKindFilter = {
  equals?: InputMaybe<TransactionKind>
  in?: InputMaybe<Array<TransactionKind>>
  not?: InputMaybe<NestedEnumTransactionKindFilter>
  notIn?: InputMaybe<Array<TransactionKind>>
}

export type NestedFloatFilter = {
  equals?: InputMaybe<Scalars['Float']>
  gt?: InputMaybe<Scalars['Float']>
  gte?: InputMaybe<Scalars['Float']>
  in?: InputMaybe<Array<Scalars['Float']>>
  lt?: InputMaybe<Scalars['Float']>
  lte?: InputMaybe<Scalars['Float']>
  not?: InputMaybe<NestedFloatFilter>
  notIn?: InputMaybe<Array<Scalars['Float']>>
}

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']>
  gt?: InputMaybe<Scalars['Int']>
  gte?: InputMaybe<Scalars['Int']>
  in?: InputMaybe<Array<Scalars['Int']>>
  lt?: InputMaybe<Scalars['Int']>
  lte?: InputMaybe<Scalars['Int']>
  not?: InputMaybe<NestedIntFilter>
  notIn?: InputMaybe<Array<Scalars['Int']>>
}

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>
  gt?: InputMaybe<Scalars['Int']>
  gte?: InputMaybe<Scalars['Int']>
  in?: InputMaybe<Array<Scalars['Int']>>
  lt?: InputMaybe<Scalars['Int']>
  lte?: InputMaybe<Scalars['Int']>
  not?: InputMaybe<NestedIntNullableFilter>
  notIn?: InputMaybe<Array<Scalars['Int']>>
}

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']>
  endsWith?: InputMaybe<Scalars['String']>
  equals?: InputMaybe<Scalars['String']>
  gt?: InputMaybe<Scalars['String']>
  gte?: InputMaybe<Scalars['String']>
  in?: InputMaybe<Array<Scalars['String']>>
  lt?: InputMaybe<Scalars['String']>
  lte?: InputMaybe<Scalars['String']>
  not?: InputMaybe<NestedStringFilter>
  notIn?: InputMaybe<Array<Scalars['String']>>
  startsWith?: InputMaybe<Scalars['String']>
}

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>
  endsWith?: InputMaybe<Scalars['String']>
  equals?: InputMaybe<Scalars['String']>
  gt?: InputMaybe<Scalars['String']>
  gte?: InputMaybe<Scalars['String']>
  in?: InputMaybe<Array<Scalars['String']>>
  lt?: InputMaybe<Scalars['String']>
  lte?: InputMaybe<Scalars['String']>
  not?: InputMaybe<NestedStringNullableFilter>
  notIn?: InputMaybe<Array<Scalars['String']>>
  startsWith?: InputMaybe<Scalars['String']>
}

export enum Plan {
  Advanced = 'Advanced',
  Basic = 'Basic',
  Expert = 'Expert',
  Starter = 'Starter',
  Ultimate = 'Ultimate',
}

export type Promocode = {
  __typename?: 'Promocode'
  amount: Scalars['Float']
  createdBy?: Maybe<User>
  id: Scalars['String']
}

export type PromocodeListRelationFilter = {
  every?: InputMaybe<PromocodeWhereInput>
  none?: InputMaybe<PromocodeWhereInput>
  some?: InputMaybe<PromocodeWhereInput>
}

export type PromocodeOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>
}

export type PromocodeWhereInput = {
  AND?: InputMaybe<Array<PromocodeWhereInput>>
  NOT?: InputMaybe<Array<PromocodeWhereInput>>
  OR?: InputMaybe<Array<PromocodeWhereInput>>
  amount?: InputMaybe<FloatFilter>
  createdBy?: InputMaybe<UserWhereInput>
  createdById?: InputMaybe<IntNullableFilter>
  id?: InputMaybe<StringFilter>
  user?: InputMaybe<UserWhereInput>
  userId?: InputMaybe<IntNullableFilter>
}

export type Purchase = {
  __typename?: 'Purchase'
  transaction?: Maybe<Transaction>
  user?: Maybe<User>
}

export type Query = {
  __typename?: 'Query'
  checkCredentials?: Maybe<CheckCredentials>
  dashboard?: Maybe<Dashboard>
  promocode?: Maybe<Promocode>
  referalls?: Maybe<Array<Maybe<User>>>
  tickets?: Maybe<Array<Maybe<Ticket>>>
  transactions: Array<Transaction>
  user?: Maybe<User>
  wallets?: Maybe<Array<Maybe<Wallet>>>
}

export type QueryCheckCredentialsArgs = {
  emailOrPhoneNumber: Scalars['String']
}

export type QueryDashboardArgs = {
  userId?: InputMaybe<Scalars['Int']>
}

export type QueryPromocodeArgs = {
  id: Scalars['ID']
}

export type QueryTicketsArgs = {
  id: Scalars['Int']
}

export type QueryTransactionsArgs = {
  after?: InputMaybe<TransactionWhereUniqueInput>
  before?: InputMaybe<TransactionWhereUniqueInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<TransactionOrderByWithRelationInput>>
  where?: InputMaybe<TransactionWhereInput>
}

export type QueryUserArgs = {
  id?: InputMaybe<Scalars['Int']>
}

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive',
}

export type Revenue = {
  __typename?: 'Revenue'
  btcDeposit?: Maybe<Scalars['Float']>
  deposit?: Maybe<Scalars['Float']>
  income?: Maybe<Scalars['Float']>
  network?: Maybe<Scalars['Float']>
  purchase?: Maybe<Scalars['Float']>
  referral?: Maybe<Scalars['Float']>
  transfer?: Maybe<Scalars['Float']>
  withdraw?: Maybe<Scalars['Float']>
}

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']>
  endsWith?: InputMaybe<Scalars['String']>
  equals?: InputMaybe<Scalars['String']>
  gt?: InputMaybe<Scalars['String']>
  gte?: InputMaybe<Scalars['String']>
  in?: InputMaybe<Array<Scalars['String']>>
  lt?: InputMaybe<Scalars['String']>
  lte?: InputMaybe<Scalars['String']>
  mode?: InputMaybe<QueryMode>
  not?: InputMaybe<NestedStringFilter>
  notIn?: InputMaybe<Array<Scalars['String']>>
  startsWith?: InputMaybe<Scalars['String']>
}

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>
  endsWith?: InputMaybe<Scalars['String']>
  equals?: InputMaybe<Scalars['String']>
  gt?: InputMaybe<Scalars['String']>
  gte?: InputMaybe<Scalars['String']>
  in?: InputMaybe<Array<Scalars['String']>>
  lt?: InputMaybe<Scalars['String']>
  lte?: InputMaybe<Scalars['String']>
  mode?: InputMaybe<QueryMode>
  not?: InputMaybe<NestedStringNullableFilter>
  notIn?: InputMaybe<Array<Scalars['String']>>
  startsWith?: InputMaybe<Scalars['String']>
}

export type Ticket = {
  __typename?: 'Ticket'
  id: Scalars['Int']
  user: User
}

export type TicketListRelationFilter = {
  every?: InputMaybe<TicketWhereInput>
  none?: InputMaybe<TicketWhereInput>
  some?: InputMaybe<TicketWhereInput>
}

export type TicketOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>
}

export type TicketWhereInput = {
  AND?: InputMaybe<Array<TicketWhereInput>>
  NOT?: InputMaybe<Array<TicketWhereInput>>
  OR?: InputMaybe<Array<TicketWhereInput>>
  id?: InputMaybe<IntFilter>
  user?: InputMaybe<UserWhereInput>
  userId?: InputMaybe<IntFilter>
}

export type Transaction = {
  __typename?: 'Transaction'
  amount: Scalars['Float']
  balance: Balance
  createdAt: Scalars['DateTime']
  curr: Scalars['Float']
  descr: Scalars['Json']
  id: Scalars['String']
  kind: TransactionKind
  prev: Scalars['Float']
  user: User
}

export enum TransactionKind {
  BtcDeposit = 'btcDeposit',
  Deposit = 'deposit',
  Income = 'income',
  Network = 'network',
  Purchase = 'purchase',
  Referral = 'referral',
  Transfer = 'transfer',
  Withdraw = 'withdraw',
}

export type TransactionListRelationFilter = {
  every?: InputMaybe<TransactionWhereInput>
  none?: InputMaybe<TransactionWhereInput>
  some?: InputMaybe<TransactionWhereInput>
}

export type TransactionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>
}

export type TransactionOrderByWithRelationInput = {
  amount?: InputMaybe<SortOrder>
  balance?: InputMaybe<SortOrder>
  createdAt?: InputMaybe<SortOrder>
  curr?: InputMaybe<SortOrder>
  descr?: InputMaybe<SortOrder>
  id?: InputMaybe<SortOrder>
  kind?: InputMaybe<SortOrder>
  prev?: InputMaybe<SortOrder>
  txId?: InputMaybe<SortOrder>
  user?: InputMaybe<UserOrderByWithRelationInput>
  userId?: InputMaybe<SortOrder>
}

export type TransactionWhereInput = {
  AND?: InputMaybe<Array<TransactionWhereInput>>
  NOT?: InputMaybe<Array<TransactionWhereInput>>
  OR?: InputMaybe<Array<TransactionWhereInput>>
  amount?: InputMaybe<FloatFilter>
  balance?: InputMaybe<EnumBalanceFilter>
  createdAt?: InputMaybe<DateTimeFilter>
  curr?: InputMaybe<FloatFilter>
  id?: InputMaybe<StringFilter>
  kind?: InputMaybe<EnumTransactionKindFilter>
  prev?: InputMaybe<FloatFilter>
  txId?: InputMaybe<StringNullableFilter>
  user?: InputMaybe<UserWhereInput>
  userId?: InputMaybe<IntFilter>
}

export type TransactionWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>
}

export type TwoFactor = {
  __typename?: 'TwoFactor'
  enabled?: Maybe<Scalars['Boolean']>
  qr?: Maybe<Scalars['String']>
  secret?: Maybe<Scalars['String']>
  tokens?: Maybe<Array<Maybe<Scalars['String']>>>
  userId: Scalars['Int']
}

export type TwoFactorOrderByWithRelationInput = {
  enabled?: InputMaybe<SortOrder>
  secret?: InputMaybe<SortOrder>
  tokens?: InputMaybe<SortOrder>
  user?: InputMaybe<UserOrderByWithRelationInput>
  userId?: InputMaybe<SortOrder>
}

export type TwoFactorWhereInput = {
  AND?: InputMaybe<Array<TwoFactorWhereInput>>
  NOT?: InputMaybe<Array<TwoFactorWhereInput>>
  OR?: InputMaybe<Array<TwoFactorWhereInput>>
  enabled?: InputMaybe<BoolFilter>
  secret?: InputMaybe<StringFilter>
  user?: InputMaybe<UserWhereInput>
  userId?: InputMaybe<IntFilter>
}

export type User = {
  __typename?: 'User'
  balance: Scalars['Float']
  bonusBalance: Scalars['Float']
  createdAt?: Maybe<Scalars['DateTime']>
  custodialWallet?: Maybe<Scalars['String']>
  email: Scalars['String']
  expieryDate?: Maybe<Scalars['DateTime']>
  fullName?: Maybe<Scalars['String']>
  id: Scalars['Int']
  isDataHidden: Scalars['Boolean']
  isPromoActive: Scalars['Boolean']
  isVerified: Scalars['Boolean']
  /** User level, available only from the referrals query */
  level?: Maybe<Scalars['Int']>
  maxIncome: Scalars['Int']
  phoneNumber?: Maybe<Scalars['String']>
  picture?: Maybe<Asset>
  plan?: Maybe<Plan>
  planBalance: Scalars['Float']
  promoBalance: Scalars['Float']
  promocodes?: Maybe<Array<Maybe<Promocode>>>
  referer?: Maybe<User>
  referrals: Array<User>
  revenue?: Maybe<Revenue>
  rewardBalance: Scalars['Float']
  telegramId?: Maybe<Scalars['String']>
  topUpAmount: Scalars['Float']
  transactions: Array<Transaction>
  twoFactor?: Maybe<TwoFactor>
  usdtWallet?: Maybe<Scalars['String']>
  withdrawalBalance: Scalars['Float']
}

export type UserReferralsArgs = {
  after?: InputMaybe<UserWhereUniqueInput>
  before?: InputMaybe<UserWhereUniqueInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type UserTransactionsArgs = {
  after?: InputMaybe<TransactionWhereUniqueInput>
  before?: InputMaybe<TransactionWhereUniqueInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<TransactionOrderByWithRelationInput>>
  where?: InputMaybe<TransactionWhereInput>
}

export type UserListRelationFilter = {
  every?: InputMaybe<UserWhereInput>
  none?: InputMaybe<UserWhereInput>
  some?: InputMaybe<UserWhereInput>
}

export type UserOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>
}

export type UserOrderByWithRelationInput = {
  balance?: InputMaybe<SortOrder>
  bonusBalance?: InputMaybe<SortOrder>
  createdAt?: InputMaybe<SortOrder>
  creatorPromo?: InputMaybe<PromocodeOrderByRelationAggregateInput>
  custodialWallet?: InputMaybe<SortOrder>
  debt?: InputMaybe<SortOrder>
  email?: InputMaybe<SortOrder>
  expieryDate?: InputMaybe<SortOrder>
  fullName?: InputMaybe<SortOrder>
  id?: InputMaybe<SortOrder>
  isAdmin?: InputMaybe<SortOrder>
  isDataHidden?: InputMaybe<SortOrder>
  isGhosted?: InputMaybe<SortOrder>
  isPromoActive?: InputMaybe<SortOrder>
  isVerified?: InputMaybe<SortOrder>
  maxIncome?: InputMaybe<SortOrder>
  password?: InputMaybe<SortOrder>
  phoneNumber?: InputMaybe<SortOrder>
  plan?: InputMaybe<SortOrder>
  planBalance?: InputMaybe<SortOrder>
  promoBalance?: InputMaybe<SortOrder>
  referer?: InputMaybe<UserOrderByWithRelationInput>
  refererId?: InputMaybe<SortOrder>
  referrals?: InputMaybe<UserOrderByRelationAggregateInput>
  rewardBalance?: InputMaybe<SortOrder>
  telegramId?: InputMaybe<SortOrder>
  tickets?: InputMaybe<TicketOrderByRelationAggregateInput>
  topUpAmount?: InputMaybe<SortOrder>
  transactions?: InputMaybe<TransactionOrderByRelationAggregateInput>
  twoFactor?: InputMaybe<TwoFactorOrderByWithRelationInput>
  updatedAt?: InputMaybe<SortOrder>
  usdtWallet?: InputMaybe<SortOrder>
  userPromo?: InputMaybe<PromocodeOrderByRelationAggregateInput>
  withdrawalBalance?: InputMaybe<SortOrder>
}

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>
  NOT?: InputMaybe<Array<UserWhereInput>>
  OR?: InputMaybe<Array<UserWhereInput>>
  balance?: InputMaybe<FloatFilter>
  bonusBalance?: InputMaybe<FloatFilter>
  createdAt?: InputMaybe<DateTimeFilter>
  creatorPromo?: InputMaybe<PromocodeListRelationFilter>
  custodialWallet?: InputMaybe<StringNullableFilter>
  debt?: InputMaybe<FloatFilter>
  email?: InputMaybe<StringFilter>
  expieryDate?: InputMaybe<DateTimeNullableFilter>
  fullName?: InputMaybe<StringNullableFilter>
  id?: InputMaybe<IntFilter>
  isAdmin?: InputMaybe<BoolFilter>
  isDataHidden?: InputMaybe<BoolFilter>
  isGhosted?: InputMaybe<BoolFilter>
  isPromoActive?: InputMaybe<BoolFilter>
  isVerified?: InputMaybe<BoolFilter>
  maxIncome?: InputMaybe<IntFilter>
  password?: InputMaybe<StringFilter>
  phoneNumber?: InputMaybe<StringNullableFilter>
  plan?: InputMaybe<EnumPlanNullableFilter>
  planBalance?: InputMaybe<FloatFilter>
  promoBalance?: InputMaybe<FloatFilter>
  referer?: InputMaybe<UserWhereInput>
  refererId?: InputMaybe<IntNullableFilter>
  referrals?: InputMaybe<UserListRelationFilter>
  rewardBalance?: InputMaybe<FloatFilter>
  telegramId?: InputMaybe<StringNullableFilter>
  tickets?: InputMaybe<TicketListRelationFilter>
  topUpAmount?: InputMaybe<FloatFilter>
  transactions?: InputMaybe<TransactionListRelationFilter>
  twoFactor?: InputMaybe<TwoFactorWhereInput>
  updatedAt?: InputMaybe<DateTimeFilter>
  usdtWallet?: InputMaybe<StringNullableFilter>
  userPromo?: InputMaybe<PromocodeListRelationFilter>
  withdrawalBalance?: InputMaybe<FloatFilter>
}

export type UserWhereUniqueInput = {
  email?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['Int']>
}

export type Wallet = {
  __typename?: 'Wallet'
  address?: Maybe<Scalars['String']>
  coin?: Maybe<Coin>
  data?: Maybe<Scalars['WalletData']>
}

export type DashboardFragment = {
  __typename?: 'Dashboard'
  overallTS?: any | null
  firstLevelSumTS?: any | null
  overall?: any | null
  sumOfOthersBranchesTS?: any | null
  strongestBranchTS?: any | null
  l1Turnover?: number | null
  networkCount?: number | null
  promoTurnover?: any | null
}

export type DashboardQueryVariables = Exact<{ [key: string]: never }>

export type DashboardQuery = {
  __typename?: 'Query'
  dashboard?: {
    __typename?: 'Dashboard'
    overallTS?: any | null
    firstLevelSumTS?: any | null
    overall?: any | null
    sumOfOthersBranchesTS?: any | null
    strongestBranchTS?: any | null
    l1Turnover?: number | null
    networkCount?: number | null
    promoTurnover?: any | null
  } | null
}

export type PromocodesFragment = {
  __typename?: 'Promocode'
  id: string
  amount: number
  createdBy?: { __typename?: 'User'; id: number } | null
}

export type PromocodesQueryVariables = Exact<{ [key: string]: never }>

export type PromocodesQuery = {
  __typename?: 'Query'
  user?: {
    __typename?: 'User'
    promocodes?: Array<{
      __typename?: 'Promocode'
      id: string
      amount: number
    } | null> | null
  } | null
}

export type PurchaseMutationVariables = Exact<{
  plan: Plan
  topUp: Scalars['Int']
  fromPromo: Scalars['Boolean']
}>

export type PurchaseMutation = {
  __typename?: 'Mutation'
  purchase?: {
    __typename?: 'Purchase'
    user?: { __typename?: 'User'; balance: number } | null
    transaction?: { __typename?: 'Transaction'; id: string } | null
  } | null
}

export type ConsumePromocodeMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type ConsumePromocodeMutation = {
  __typename?: 'Mutation'
  consumePromocode?: { __typename?: 'Promocode'; id: string } | null
}

export type ResetPasswordMutationVariables = Exact<{
  email: Scalars['String']
  callbackUrl: Scalars['String']
}>

export type ResetPasswordMutation = {
  __typename?: 'Mutation'
  resetPassword?: boolean | null
}

export type SetPasswordMutationVariables = Exact<{
  token: Scalars['String']
  password: Scalars['String']
}>

export type SetPasswordMutation = {
  __typename?: 'Mutation'
  setPassword?: boolean | null
}

export type CheckCredentialsQueryVariables = Exact<{
  emailOrPhoneNumber: Scalars['String']
}>

export type CheckCredentialsQuery = {
  __typename?: 'Query'
  checkCredentials?: {
    __typename?: 'CheckCredentials'
    twoFactor?: boolean | null
    isVerified?: boolean | null
  } | null
}

export type SignInMutationVariables = Exact<{
  emailOrPhoneNumber: Scalars['String']
  password: Scalars['String']
  token?: InputMaybe<Scalars['String']>
}>

export type SignInMutation = { __typename?: 'Mutation'; signIn?: string | null }

export type SignUpMutationVariables = Exact<{
  email: Scalars['String']
  password: Scalars['String']
  fullName: Scalars['String']
  phoneNumber: Scalars['String']
  telegramId: Scalars['String']
  refererId?: InputMaybe<Scalars['Int']>
  callbackUrl?: InputMaybe<Scalars['String']>
}>

export type SignUpMutation = {
  __typename?: 'Mutation'
  signUp?: { __typename?: 'User'; id: number } | null
}

export type SendVerificationMutationVariables = Exact<{
  email: Scalars['String']
  callbackUrl: Scalars['String']
}>

export type SendVerificationMutation = {
  __typename?: 'Mutation'
  sendVerification?: boolean | null
}

export type VerifyEmailMutationVariables = Exact<{
  verificationToken: Scalars['String']
}>

export type VerifyEmailMutation = {
  __typename?: 'Mutation'
  verifyEmail?: boolean | null
}

export type TicketsFragment = {
  __typename?: 'Ticket'
  id: number
  user: { __typename?: 'User'; id: number }
}

export type TicketsQueryVariables = Exact<{
  id: Scalars['Int']
}>

export type TicketsQuery = {
  __typename?: 'Query'
  tickets?: Array<{
    __typename?: 'Ticket'
    id: number
    user: { __typename?: 'User'; id: number }
  } | null> | null
}

export type TransactionFragment = {
  __typename?: 'Transaction'
  id: string
  amount: number
  kind: TransactionKind
  balance: Balance
  curr: number
  prev: number
  createdAt: any
  descr: any
}

export type UserTransactionsQueryVariables = Exact<{
  after?: InputMaybe<TransactionWhereUniqueInput>
  before?: InputMaybe<TransactionWhereUniqueInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<
    | Array<TransactionOrderByWithRelationInput>
    | TransactionOrderByWithRelationInput
  >
  where?: InputMaybe<TransactionWhereInput>
}>

export type UserTransactionsQuery = {
  __typename?: 'Query'
  user?: {
    __typename?: 'User'
    transactions: Array<{
      __typename?: 'Transaction'
      id: string
      amount: number
      kind: TransactionKind
      balance: Balance
      curr: number
      prev: number
      createdAt: any
      descr: any
    }>
  } | null
}

export type TransactionsQueryVariables = Exact<{
  after?: InputMaybe<TransactionWhereUniqueInput>
  before?: InputMaybe<TransactionWhereUniqueInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<
    | Array<TransactionOrderByWithRelationInput>
    | TransactionOrderByWithRelationInput
  >
  where?: InputMaybe<TransactionWhereInput>
}>

export type TransactionsQuery = {
  __typename?: 'Query'
  transactions: Array<{
    __typename?: 'Transaction'
    id: string
    amount: number
    kind: TransactionKind
    balance: Balance
    curr: number
    prev: number
    createdAt: any
    descr: any
  }>
}

export type GetTransactionsMutationVariables = Exact<{
  kind?: InputMaybe<Scalars['String']>
  skip: Scalars['Int']
  take: Scalars['Int']
}>

export type GetTransactionsMutation = {
  __typename?: 'Mutation'
  getTransaction?: Array<{
    __typename?: 'Transaction'
    id: string
    amount: number
    kind: TransactionKind
    balance: Balance
    curr: number
    prev: number
    createdAt: any
    descr: any
  } | null> | null
}

export type TransferMutationVariables = Exact<{
  from: Balance
  to: Balance
  amount: Scalars['Float']
}>

export type TransferMutation = {
  __typename?: 'Mutation'
  transfer?: {
    __typename?: 'Transaction'
    id: string
    amount: number
    kind: TransactionKind
    balance: Balance
    curr: number
    prev: number
    createdAt: any
    descr: any
  } | null
}

export type WithdrawCodeRequestMutationVariables = Exact<{
  code: Scalars['Int']
  email: Scalars['String']
}>

export type WithdrawCodeRequestMutation = {
  __typename?: 'Mutation'
  withdrawCodeRequest?: boolean | null
}

export type WithdrawRequestMutationVariables = Exact<{
  amount: Scalars['Float']
  to: Scalars['String']
  token?: InputMaybe<Scalars['String']>
}>

export type WithdrawRequestMutation = {
  __typename?: 'Mutation'
  withdrawRequest?: {
    __typename?: 'Transaction'
    id: string
    amount: number
    kind: TransactionKind
    balance: Balance
    curr: number
    prev: number
    createdAt: any
    descr: any
  } | null
}

export type WithdrawBtcRequestMutationVariables = Exact<{
  amount: Scalars['Float']
  to: Scalars['String']
  token?: InputMaybe<Scalars['String']>
  amountInBTC: Scalars['Float']
}>

export type WithdrawBtcRequestMutation = {
  __typename?: 'Mutation'
  withdrawBTCRequest?: {
    __typename?: 'Transaction'
    id: string
    amount: number
    kind: TransactionKind
    balance: Balance
    curr: number
    prev: number
    createdAt: any
    descr: any
  } | null
}

export type BtcDepositMutationVariables = Exact<{
  amount: Scalars['Float']
  btcWallet: Scalars['String']
  amountInBTC: Scalars['Float']
  userId: Scalars['Int']
}>

export type BtcDepositMutation = {
  __typename?: 'Mutation'
  btcDeposit?: {
    __typename?: 'Transaction'
    id: string
    amount: number
    kind: TransactionKind
    balance: Balance
    curr: number
    prev: number
    createdAt: any
    descr: any
  } | null
}

export type TwoFactorFragment = {
  __typename?: 'TwoFactor'
  enabled?: boolean | null
  qr?: string | null
  secret?: string | null
  tokens?: Array<string | null> | null
}

export type SetTwoFactorMutationVariables = Exact<{
  enable: Scalars['Boolean']
}>

export type SetTwoFactorMutation = {
  __typename?: 'Mutation'
  setTwoFactor?: {
    __typename?: 'TwoFactor'
    enabled?: boolean | null
    qr?: string | null
    secret?: string | null
    tokens?: Array<string | null> | null
  } | null
}

export type TwoFactorQueryVariables = Exact<{ [key: string]: never }>

export type TwoFactorQuery = {
  __typename?: 'Query'
  user?: {
    __typename?: 'User'
    twoFactor?: {
      __typename?: 'TwoFactor'
      enabled?: boolean | null
      qr?: string | null
      secret?: string | null
      tokens?: Array<string | null> | null
    } | null
  } | null
}

export type UserPublicInfoFragment = {
  __typename: 'User'
  id: number
  fullName?: string | null
  phoneNumber?: string | null
  email: string
  createdAt?: any | null
  telegramId?: string | null
  plan?: Plan | null
  maxIncome: number
  expieryDate?: any | null
  isPromoActive: boolean
  picture?: {
    __typename?: 'Asset'
    id?: string | null
    download?: string | null
  } | null
}

export type UserInfoFragment = {
  __typename: 'User'
  id: number
  isVerified: boolean
  email: string
  custodialWallet?: string | null
  fullName?: string | null
  phoneNumber?: string | null
  maxIncome: number
  isPromoActive: boolean
  balance: number
  telegramId?: string | null
  planBalance: number
  promoBalance: number
  bonusBalance: number
  rewardBalance: number
  isDataHidden: boolean
  withdrawalBalance: number
  topUpAmount: number
  usdtWallet?: string | null
  expieryDate?: any | null
  plan?: Plan | null
  createdAt?: any | null
  picture?: {
    __typename?: 'Asset'
    id?: string | null
    download?: string | null
    upload?: string | null
  } | null
  referer?: {
    __typename?: 'User'
    id: number
    fullName?: string | null
    phoneNumber?: string | null
    email: string
    picture?: {
      __typename?: 'Asset'
      id?: string | null
      download?: string | null
    } | null
  } | null
  referrals: Array<{
    __typename?: 'User'
    id: number
    fullName?: string | null
    phoneNumber?: string | null
    email: string
    telegramId?: string | null
    plan?: Plan | null
    isDataHidden: boolean
    expieryDate?: any | null
    createdAt?: any | null
    isPromoActive: boolean
    maxIncome: number
    picture?: {
      __typename?: 'Asset'
      id?: string | null
      download?: string | null
    } | null
    revenue?: {
      __typename?: 'Revenue'
      deposit?: number | null
      income?: number | null
      network?: number | null
      purchase?: number | null
      referral?: number | null
      transfer?: number | null
      withdraw?: number | null
    } | null
  }>
  revenue?: {
    __typename?: 'Revenue'
    deposit?: number | null
    income?: number | null
    network?: number | null
    purchase?: number | null
    referral?: number | null
    transfer?: number | null
    withdraw?: number | null
  } | null
  twoFactor?: {
    __typename?: 'TwoFactor'
    enabled?: boolean | null
    secret?: string | null
    qr?: string | null
    tokens?: Array<string | null> | null
  } | null
}

export type NetworkFragment = {
  __typename: 'User'
  id: number
  isVerified: boolean
  email: string
  custodialWallet?: string | null
  fullName?: string | null
  phoneNumber?: string | null
  maxIncome: number
  isPromoActive: boolean
  balance: number
  telegramId?: string | null
  planBalance: number
  promoBalance: number
  bonusBalance: number
  rewardBalance: number
  isDataHidden: boolean
  withdrawalBalance: number
  topUpAmount: number
  usdtWallet?: string | null
  expieryDate?: any | null
  plan?: Plan | null
  createdAt?: any | null
  referrals: Array<{
    __typename: 'User'
    id: number
    fullName?: string | null
    phoneNumber?: string | null
    email: string
    telegramId?: string | null
    plan?: Plan | null
    isDataHidden: boolean
    expieryDate?: any | null
    createdAt?: any | null
    isPromoActive: boolean
    maxIncome: number
    referrals: Array<{
      __typename: 'User'
      id: number
      fullName?: string | null
      phoneNumber?: string | null
      email: string
      createdAt?: any | null
      telegramId?: string | null
      plan?: Plan | null
      maxIncome: number
      expieryDate?: any | null
      isPromoActive: boolean
      referrals: Array<{
        __typename: 'User'
        id: number
        fullName?: string | null
        phoneNumber?: string | null
        email: string
        createdAt?: any | null
        telegramId?: string | null
        plan?: Plan | null
        maxIncome: number
        expieryDate?: any | null
        isPromoActive: boolean
        referrals: Array<{
          __typename: 'User'
          id: number
          fullName?: string | null
          phoneNumber?: string | null
          email: string
          createdAt?: any | null
          telegramId?: string | null
          plan?: Plan | null
          maxIncome: number
          expieryDate?: any | null
          isPromoActive: boolean
          referrals: Array<{
            __typename: 'User'
            id: number
            fullName?: string | null
            phoneNumber?: string | null
            email: string
            createdAt?: any | null
            telegramId?: string | null
            plan?: Plan | null
            maxIncome: number
            expieryDate?: any | null
            isPromoActive: boolean
            referrals: Array<{
              __typename: 'User'
              id: number
              fullName?: string | null
              phoneNumber?: string | null
              email: string
              createdAt?: any | null
              telegramId?: string | null
              plan?: Plan | null
              maxIncome: number
              expieryDate?: any | null
              isPromoActive: boolean
              referrals: Array<{
                __typename: 'User'
                id: number
                fullName?: string | null
                phoneNumber?: string | null
                email: string
                createdAt?: any | null
                telegramId?: string | null
                plan?: Plan | null
                maxIncome: number
                expieryDate?: any | null
                isPromoActive: boolean
                referrals: Array<{
                  __typename: 'User'
                  id: number
                  fullName?: string | null
                  phoneNumber?: string | null
                  email: string
                  createdAt?: any | null
                  telegramId?: string | null
                  plan?: Plan | null
                  maxIncome: number
                  expieryDate?: any | null
                  isPromoActive: boolean
                  referrals: Array<{
                    __typename: 'User'
                    id: number
                    fullName?: string | null
                    phoneNumber?: string | null
                    email: string
                    createdAt?: any | null
                    telegramId?: string | null
                    plan?: Plan | null
                    maxIncome: number
                    expieryDate?: any | null
                    isPromoActive: boolean
                    referrals: Array<{
                      __typename: 'User'
                      id: number
                      fullName?: string | null
                      phoneNumber?: string | null
                      email: string
                      createdAt?: any | null
                      telegramId?: string | null
                      plan?: Plan | null
                      maxIncome: number
                      expieryDate?: any | null
                      isPromoActive: boolean
                      picture?: {
                        __typename?: 'Asset'
                        id?: string | null
                        download?: string | null
                      } | null
                    }>
                    picture?: {
                      __typename?: 'Asset'
                      id?: string | null
                      download?: string | null
                    } | null
                  }>
                  picture?: {
                    __typename?: 'Asset'
                    id?: string | null
                    download?: string | null
                  } | null
                }>
                picture?: {
                  __typename?: 'Asset'
                  id?: string | null
                  download?: string | null
                } | null
              }>
              picture?: {
                __typename?: 'Asset'
                id?: string | null
                download?: string | null
              } | null
            }>
            picture?: {
              __typename?: 'Asset'
              id?: string | null
              download?: string | null
            } | null
          }>
          picture?: {
            __typename?: 'Asset'
            id?: string | null
            download?: string | null
          } | null
        }>
        picture?: {
          __typename?: 'Asset'
          id?: string | null
          download?: string | null
        } | null
      }>
      picture?: {
        __typename?: 'Asset'
        id?: string | null
        download?: string | null
      } | null
    }>
    picture?: {
      __typename?: 'Asset'
      id?: string | null
      download?: string | null
    } | null
    revenue?: {
      __typename?: 'Revenue'
      deposit?: number | null
      income?: number | null
      network?: number | null
      purchase?: number | null
      referral?: number | null
      transfer?: number | null
      withdraw?: number | null
    } | null
  }>
  picture?: {
    __typename?: 'Asset'
    id?: string | null
    download?: string | null
    upload?: string | null
  } | null
  referer?: {
    __typename?: 'User'
    id: number
    fullName?: string | null
    phoneNumber?: string | null
    email: string
    picture?: {
      __typename?: 'Asset'
      id?: string | null
      download?: string | null
    } | null
  } | null
  revenue?: {
    __typename?: 'Revenue'
    deposit?: number | null
    income?: number | null
    network?: number | null
    purchase?: number | null
    referral?: number | null
    transfer?: number | null
    withdraw?: number | null
  } | null
  twoFactor?: {
    __typename?: 'TwoFactor'
    enabled?: boolean | null
    secret?: string | null
    qr?: string | null
    tokens?: Array<string | null> | null
  } | null
}

export type IamQueryVariables = Exact<{ [key: string]: never }>

export type IamQuery = {
  __typename?: 'Query'
  user?: {
    __typename: 'User'
    id: number
    isVerified: boolean
    email: string
    custodialWallet?: string | null
    fullName?: string | null
    phoneNumber?: string | null
    maxIncome: number
    isPromoActive: boolean
    balance: number
    telegramId?: string | null
    planBalance: number
    promoBalance: number
    bonusBalance: number
    rewardBalance: number
    isDataHidden: boolean
    withdrawalBalance: number
    topUpAmount: number
    usdtWallet?: string | null
    expieryDate?: any | null
    plan?: Plan | null
    createdAt?: any | null
    picture?: {
      __typename?: 'Asset'
      id?: string | null
      download?: string | null
      upload?: string | null
    } | null
    referer?: {
      __typename?: 'User'
      id: number
      fullName?: string | null
      phoneNumber?: string | null
      email: string
      picture?: {
        __typename?: 'Asset'
        id?: string | null
        download?: string | null
      } | null
    } | null
    referrals: Array<{
      __typename?: 'User'
      id: number
      fullName?: string | null
      phoneNumber?: string | null
      email: string
      telegramId?: string | null
      plan?: Plan | null
      isDataHidden: boolean
      expieryDate?: any | null
      createdAt?: any | null
      isPromoActive: boolean
      maxIncome: number
      picture?: {
        __typename?: 'Asset'
        id?: string | null
        download?: string | null
      } | null
      revenue?: {
        __typename?: 'Revenue'
        deposit?: number | null
        income?: number | null
        network?: number | null
        purchase?: number | null
        referral?: number | null
        transfer?: number | null
        withdraw?: number | null
      } | null
    }>
    revenue?: {
      __typename?: 'Revenue'
      deposit?: number | null
      income?: number | null
      network?: number | null
      purchase?: number | null
      referral?: number | null
      transfer?: number | null
      withdraw?: number | null
    } | null
    twoFactor?: {
      __typename?: 'TwoFactor'
      enabled?: boolean | null
      secret?: string | null
      qr?: string | null
      tokens?: Array<string | null> | null
    } | null
  } | null
}

export type UserQueryVariables = Exact<{
  id: Scalars['Int']
}>

export type UserQuery = {
  __typename?: 'Query'
  user?: {
    __typename: 'User'
    id: number
    fullName?: string | null
    phoneNumber?: string | null
    email: string
    createdAt?: any | null
    telegramId?: string | null
    plan?: Plan | null
    maxIncome: number
    expieryDate?: any | null
    isPromoActive: boolean
    picture?: {
      __typename?: 'Asset'
      id?: string | null
      download?: string | null
    } | null
  } | null
}

export type ReferrerQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>
}>

export type ReferrerQuery = {
  __typename?: 'Query'
  user?: {
    __typename: 'User'
    id: number
    fullName?: string | null
    phoneNumber?: string | null
    email: string
    createdAt?: any | null
    telegramId?: string | null
    plan?: Plan | null
    maxIncome: number
    expieryDate?: any | null
    isPromoActive: boolean
    picture?: {
      __typename?: 'Asset'
      id?: string | null
      download?: string | null
    } | null
  } | null
}

export type ReferralQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>
}>

export type ReferralQuery = {
  __typename?: 'Query'
  user?: {
    __typename: 'User'
    id: number
    isVerified: boolean
    email: string
    custodialWallet?: string | null
    fullName?: string | null
    phoneNumber?: string | null
    maxIncome: number
    isPromoActive: boolean
    balance: number
    telegramId?: string | null
    planBalance: number
    promoBalance: number
    bonusBalance: number
    rewardBalance: number
    isDataHidden: boolean
    withdrawalBalance: number
    topUpAmount: number
    usdtWallet?: string | null
    expieryDate?: any | null
    plan?: Plan | null
    createdAt?: any | null
    referrals: Array<{
      __typename: 'User'
      id: number
      fullName?: string | null
      phoneNumber?: string | null
      email: string
      telegramId?: string | null
      plan?: Plan | null
      isDataHidden: boolean
      expieryDate?: any | null
      createdAt?: any | null
      isPromoActive: boolean
      maxIncome: number
      referrals: Array<{
        __typename: 'User'
        id: number
        fullName?: string | null
        phoneNumber?: string | null
        email: string
        createdAt?: any | null
        telegramId?: string | null
        plan?: Plan | null
        maxIncome: number
        expieryDate?: any | null
        isPromoActive: boolean
        referrals: Array<{
          __typename: 'User'
          id: number
          fullName?: string | null
          phoneNumber?: string | null
          email: string
          createdAt?: any | null
          telegramId?: string | null
          plan?: Plan | null
          maxIncome: number
          expieryDate?: any | null
          isPromoActive: boolean
          referrals: Array<{
            __typename: 'User'
            id: number
            fullName?: string | null
            phoneNumber?: string | null
            email: string
            createdAt?: any | null
            telegramId?: string | null
            plan?: Plan | null
            maxIncome: number
            expieryDate?: any | null
            isPromoActive: boolean
            referrals: Array<{
              __typename: 'User'
              id: number
              fullName?: string | null
              phoneNumber?: string | null
              email: string
              createdAt?: any | null
              telegramId?: string | null
              plan?: Plan | null
              maxIncome: number
              expieryDate?: any | null
              isPromoActive: boolean
              referrals: Array<{
                __typename: 'User'
                id: number
                fullName?: string | null
                phoneNumber?: string | null
                email: string
                createdAt?: any | null
                telegramId?: string | null
                plan?: Plan | null
                maxIncome: number
                expieryDate?: any | null
                isPromoActive: boolean
                referrals: Array<{
                  __typename: 'User'
                  id: number
                  fullName?: string | null
                  phoneNumber?: string | null
                  email: string
                  createdAt?: any | null
                  telegramId?: string | null
                  plan?: Plan | null
                  maxIncome: number
                  expieryDate?: any | null
                  isPromoActive: boolean
                  referrals: Array<{
                    __typename: 'User'
                    id: number
                    fullName?: string | null
                    phoneNumber?: string | null
                    email: string
                    createdAt?: any | null
                    telegramId?: string | null
                    plan?: Plan | null
                    maxIncome: number
                    expieryDate?: any | null
                    isPromoActive: boolean
                    referrals: Array<{
                      __typename: 'User'
                      id: number
                      fullName?: string | null
                      phoneNumber?: string | null
                      email: string
                      createdAt?: any | null
                      telegramId?: string | null
                      plan?: Plan | null
                      maxIncome: number
                      expieryDate?: any | null
                      isPromoActive: boolean
                      referrals: Array<{
                        __typename: 'User'
                        id: number
                        fullName?: string | null
                        phoneNumber?: string | null
                        email: string
                        createdAt?: any | null
                        telegramId?: string | null
                        plan?: Plan | null
                        maxIncome: number
                        expieryDate?: any | null
                        isPromoActive: boolean
                        picture?: {
                          __typename?: 'Asset'
                          id?: string | null
                          download?: string | null
                        } | null
                      }>
                      picture?: {
                        __typename?: 'Asset'
                        id?: string | null
                        download?: string | null
                      } | null
                    }>
                    picture?: {
                      __typename?: 'Asset'
                      id?: string | null
                      download?: string | null
                    } | null
                  }>
                  picture?: {
                    __typename?: 'Asset'
                    id?: string | null
                    download?: string | null
                  } | null
                }>
                picture?: {
                  __typename?: 'Asset'
                  id?: string | null
                  download?: string | null
                } | null
              }>
              picture?: {
                __typename?: 'Asset'
                id?: string | null
                download?: string | null
              } | null
            }>
            picture?: {
              __typename?: 'Asset'
              id?: string | null
              download?: string | null
            } | null
          }>
          picture?: {
            __typename?: 'Asset'
            id?: string | null
            download?: string | null
          } | null
        }>
        picture?: {
          __typename?: 'Asset'
          id?: string | null
          download?: string | null
        } | null
      }>
      picture?: {
        __typename?: 'Asset'
        id?: string | null
        download?: string | null
      } | null
      revenue?: {
        __typename?: 'Revenue'
        deposit?: number | null
        income?: number | null
        network?: number | null
        purchase?: number | null
        referral?: number | null
        transfer?: number | null
        withdraw?: number | null
      } | null
    }>
    picture?: {
      __typename?: 'Asset'
      id?: string | null
      download?: string | null
      upload?: string | null
    } | null
    referer?: {
      __typename?: 'User'
      id: number
      fullName?: string | null
      phoneNumber?: string | null
      email: string
      picture?: {
        __typename?: 'Asset'
        id?: string | null
        download?: string | null
      } | null
    } | null
    revenue?: {
      __typename?: 'Revenue'
      deposit?: number | null
      income?: number | null
      network?: number | null
      purchase?: number | null
      referral?: number | null
      transfer?: number | null
      withdraw?: number | null
    } | null
    twoFactor?: {
      __typename?: 'TwoFactor'
      enabled?: boolean | null
      secret?: string | null
      qr?: string | null
      tokens?: Array<string | null> | null
    } | null
  } | null
}

export type UpdateMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>
  password?: InputMaybe<Scalars['String']>
  oldPassword?: InputMaybe<Scalars['String']>
}>

export type UpdateMutation = {
  __typename?: 'Mutation'
  updateUser?: {
    __typename: 'User'
    id: number
    isVerified: boolean
    email: string
    custodialWallet?: string | null
    fullName?: string | null
    phoneNumber?: string | null
    maxIncome: number
    isPromoActive: boolean
    balance: number
    telegramId?: string | null
    planBalance: number
    promoBalance: number
    bonusBalance: number
    rewardBalance: number
    isDataHidden: boolean
    withdrawalBalance: number
    topUpAmount: number
    usdtWallet?: string | null
    expieryDate?: any | null
    plan?: Plan | null
    createdAt?: any | null
    picture?: {
      __typename?: 'Asset'
      id?: string | null
      download?: string | null
      upload?: string | null
    } | null
    referer?: {
      __typename?: 'User'
      id: number
      fullName?: string | null
      phoneNumber?: string | null
      email: string
      picture?: {
        __typename?: 'Asset'
        id?: string | null
        download?: string | null
      } | null
    } | null
    referrals: Array<{
      __typename?: 'User'
      id: number
      fullName?: string | null
      phoneNumber?: string | null
      email: string
      telegramId?: string | null
      plan?: Plan | null
      isDataHidden: boolean
      expieryDate?: any | null
      createdAt?: any | null
      isPromoActive: boolean
      maxIncome: number
      picture?: {
        __typename?: 'Asset'
        id?: string | null
        download?: string | null
      } | null
      revenue?: {
        __typename?: 'Revenue'
        deposit?: number | null
        income?: number | null
        network?: number | null
        purchase?: number | null
        referral?: number | null
        transfer?: number | null
        withdraw?: number | null
      } | null
    }>
    revenue?: {
      __typename?: 'Revenue'
      deposit?: number | null
      income?: number | null
      network?: number | null
      purchase?: number | null
      referral?: number | null
      transfer?: number | null
      withdraw?: number | null
    } | null
    twoFactor?: {
      __typename?: 'TwoFactor'
      enabled?: boolean | null
      secret?: string | null
      qr?: string | null
      tokens?: Array<string | null> | null
    } | null
  } | null
}

export type HideMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>
  hideData: Scalars['Boolean']
}>

export type HideMutation = {
  __typename?: 'Mutation'
  hideData?: {
    __typename: 'User'
    id: number
    isVerified: boolean
    email: string
    custodialWallet?: string | null
    fullName?: string | null
    phoneNumber?: string | null
    maxIncome: number
    isPromoActive: boolean
    balance: number
    telegramId?: string | null
    planBalance: number
    promoBalance: number
    bonusBalance: number
    rewardBalance: number
    isDataHidden: boolean
    withdrawalBalance: number
    topUpAmount: number
    usdtWallet?: string | null
    expieryDate?: any | null
    plan?: Plan | null
    createdAt?: any | null
    picture?: {
      __typename?: 'Asset'
      id?: string | null
      download?: string | null
      upload?: string | null
    } | null
    referer?: {
      __typename?: 'User'
      id: number
      fullName?: string | null
      phoneNumber?: string | null
      email: string
      picture?: {
        __typename?: 'Asset'
        id?: string | null
        download?: string | null
      } | null
    } | null
    referrals: Array<{
      __typename?: 'User'
      id: number
      fullName?: string | null
      phoneNumber?: string | null
      email: string
      telegramId?: string | null
      plan?: Plan | null
      isDataHidden: boolean
      expieryDate?: any | null
      createdAt?: any | null
      isPromoActive: boolean
      maxIncome: number
      picture?: {
        __typename?: 'Asset'
        id?: string | null
        download?: string | null
      } | null
      revenue?: {
        __typename?: 'Revenue'
        deposit?: number | null
        income?: number | null
        network?: number | null
        purchase?: number | null
        referral?: number | null
        transfer?: number | null
        withdraw?: number | null
      } | null
    }>
    revenue?: {
      __typename?: 'Revenue'
      deposit?: number | null
      income?: number | null
      network?: number | null
      purchase?: number | null
      referral?: number | null
      transfer?: number | null
      withdraw?: number | null
    } | null
    twoFactor?: {
      __typename?: 'TwoFactor'
      enabled?: boolean | null
      secret?: string | null
      qr?: string | null
      tokens?: Array<string | null> | null
    } | null
  } | null
}

export type AssignUsdtWalletMutationVariables = Exact<{
  usdtWallet: Scalars['String']
}>

export type AssignUsdtWalletMutation = {
  __typename?: 'Mutation'
  assignUSDTWallet?: {
    __typename: 'User'
    id: number
    isVerified: boolean
    email: string
    custodialWallet?: string | null
    fullName?: string | null
    phoneNumber?: string | null
    maxIncome: number
    isPromoActive: boolean
    balance: number
    telegramId?: string | null
    planBalance: number
    promoBalance: number
    bonusBalance: number
    rewardBalance: number
    isDataHidden: boolean
    withdrawalBalance: number
    topUpAmount: number
    usdtWallet?: string | null
    expieryDate?: any | null
    plan?: Plan | null
    createdAt?: any | null
    picture?: {
      __typename?: 'Asset'
      id?: string | null
      download?: string | null
      upload?: string | null
    } | null
    referer?: {
      __typename?: 'User'
      id: number
      fullName?: string | null
      phoneNumber?: string | null
      email: string
      picture?: {
        __typename?: 'Asset'
        id?: string | null
        download?: string | null
      } | null
    } | null
    referrals: Array<{
      __typename?: 'User'
      id: number
      fullName?: string | null
      phoneNumber?: string | null
      email: string
      telegramId?: string | null
      plan?: Plan | null
      isDataHidden: boolean
      expieryDate?: any | null
      createdAt?: any | null
      isPromoActive: boolean
      maxIncome: number
      picture?: {
        __typename?: 'Asset'
        id?: string | null
        download?: string | null
      } | null
      revenue?: {
        __typename?: 'Revenue'
        deposit?: number | null
        income?: number | null
        network?: number | null
        purchase?: number | null
        referral?: number | null
        transfer?: number | null
        withdraw?: number | null
      } | null
    }>
    revenue?: {
      __typename?: 'Revenue'
      deposit?: number | null
      income?: number | null
      network?: number | null
      purchase?: number | null
      referral?: number | null
      transfer?: number | null
      withdraw?: number | null
    } | null
    twoFactor?: {
      __typename?: 'TwoFactor'
      enabled?: boolean | null
      secret?: string | null
      qr?: string | null
      tokens?: Array<string | null> | null
    } | null
  } | null
}

export type DeleteUserPromoMutationVariables = Exact<{
  id: Scalars['Int']
}>

export type DeleteUserPromoMutation = {
  __typename?: 'Mutation'
  deleteUserPromo?: {
    __typename: 'User'
    id: number
    isVerified: boolean
    email: string
    custodialWallet?: string | null
    fullName?: string | null
    phoneNumber?: string | null
    maxIncome: number
    isPromoActive: boolean
    balance: number
    telegramId?: string | null
    planBalance: number
    promoBalance: number
    bonusBalance: number
    rewardBalance: number
    isDataHidden: boolean
    withdrawalBalance: number
    topUpAmount: number
    usdtWallet?: string | null
    expieryDate?: any | null
    plan?: Plan | null
    createdAt?: any | null
    picture?: {
      __typename?: 'Asset'
      id?: string | null
      download?: string | null
      upload?: string | null
    } | null
    referer?: {
      __typename?: 'User'
      id: number
      fullName?: string | null
      phoneNumber?: string | null
      email: string
      picture?: {
        __typename?: 'Asset'
        id?: string | null
        download?: string | null
      } | null
    } | null
    referrals: Array<{
      __typename?: 'User'
      id: number
      fullName?: string | null
      phoneNumber?: string | null
      email: string
      telegramId?: string | null
      plan?: Plan | null
      isDataHidden: boolean
      expieryDate?: any | null
      createdAt?: any | null
      isPromoActive: boolean
      maxIncome: number
      picture?: {
        __typename?: 'Asset'
        id?: string | null
        download?: string | null
      } | null
      revenue?: {
        __typename?: 'Revenue'
        deposit?: number | null
        income?: number | null
        network?: number | null
        purchase?: number | null
        referral?: number | null
        transfer?: number | null
        withdraw?: number | null
      } | null
    }>
    revenue?: {
      __typename?: 'Revenue'
      deposit?: number | null
      income?: number | null
      network?: number | null
      purchase?: number | null
      referral?: number | null
      transfer?: number | null
      withdraw?: number | null
    } | null
    twoFactor?: {
      __typename?: 'TwoFactor'
      enabled?: boolean | null
      secret?: string | null
      qr?: string | null
      tokens?: Array<string | null> | null
    } | null
  } | null
}

export type DepositMutationVariables = Exact<{ [key: string]: never }>

export type DepositMutation = {
  __typename?: 'Mutation'
  deposit?: string | null
}

export const DashboardFragmentDoc = gql`
  fragment Dashboard on Dashboard {
    overallTS
    firstLevelSumTS
    overall
    sumOfOthersBranchesTS
    strongestBranchTS
    l1Turnover
    networkCount
    promoTurnover
  }
`
export const PromocodesFragmentDoc = gql`
  fragment Promocodes on Promocode {
    id
    amount
    createdBy {
      id
    }
  }
`
export const TicketsFragmentDoc = gql`
  fragment Tickets on Ticket {
    id
    user {
      id
    }
  }
`
export const TransactionFragmentDoc = gql`
  fragment Transaction on Transaction {
    id
    amount
    kind
    balance
    curr
    prev
    createdAt
    descr
  }
`
export const TwoFactorFragmentDoc = gql`
  fragment TwoFactor on TwoFactor {
    enabled
    qr
    secret
    tokens
  }
`
export const UserInfoFragmentDoc = gql`
  fragment UserInfo on User {
    id
    picture {
      id
      download
      upload
    }
    isVerified
    email
    custodialWallet
    fullName
    phoneNumber
    maxIncome
    isPromoActive
    balance
    telegramId
    planBalance
    promoBalance
    bonusBalance
    rewardBalance
    isDataHidden
    withdrawalBalance
    topUpAmount
    usdtWallet
    expieryDate
    plan
    referer {
      id
      fullName
      picture {
        id
        download
      }
      phoneNumber
      email
    }
    referrals {
      id
      fullName
      phoneNumber
      email
      telegramId
      plan
      isDataHidden
      expieryDate
      createdAt
      isPromoActive
      maxIncome
      picture {
        id
        download
      }
      revenue {
        deposit
        income
        network
        purchase
        referral
        transfer
        withdraw
      }
    }
    revenue {
      deposit
      income
      network
      purchase
      referral
      transfer
      withdraw
    }
    twoFactor {
      enabled
      secret
      qr
      tokens
    }
    createdAt
    __typename
  }
`
export const UserPublicInfoFragmentDoc = gql`
  fragment UserPublicInfo on User {
    id
    picture {
      id
      download
    }
    fullName
    phoneNumber
    email
    createdAt
    telegramId
    plan
    maxIncome
    expieryDate
    isPromoActive
    __typename
  }
`
export const NetworkFragmentDoc = gql`
  fragment Network on User {
    ...UserInfo
    referrals {
      ...UserPublicInfo
      referrals {
        ...UserPublicInfo
        referrals {
          ...UserPublicInfo
          referrals {
            ...UserPublicInfo
            referrals {
              ...UserPublicInfo
              referrals {
                ...UserPublicInfo
                referrals {
                  ...UserPublicInfo
                  referrals {
                    ...UserPublicInfo
                    referrals {
                      ...UserPublicInfo
                      referrals {
                        ...UserPublicInfo
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${UserInfoFragmentDoc}
  ${UserPublicInfoFragmentDoc}
`
export const DashboardDocument = gql`
  query dashboard {
    dashboard {
      ...Dashboard
    }
  }
  ${DashboardFragmentDoc}
`
export const PromocodesDocument = gql`
  query promocodes {
    user {
      promocodes {
        id
        amount
      }
    }
  }
`
export const PurchaseDocument = gql`
  mutation purchase($plan: Plan!, $topUp: Int!, $fromPromo: Boolean!) {
    purchase(plan: $plan, topUp: $topUp, fromPromo: $fromPromo) {
      user {
        balance
      }
      transaction {
        id
      }
    }
  }
`
export const ConsumePromocodeDocument = gql`
  mutation consumePromocode($id: ID!) {
    consumePromocode(id: $id) {
      id
    }
  }
`
export const ResetPasswordDocument = gql`
  mutation resetPassword($email: String!, $callbackUrl: String!) {
    resetPassword(email: $email, callbackUrl: $callbackUrl)
  }
`
export const SetPasswordDocument = gql`
  mutation setPassword($token: String!, $password: String!) {
    setPassword(token: $token, password: $password)
  }
`
export const CheckCredentialsDocument = gql`
  query checkCredentials($emailOrPhoneNumber: String!) {
    checkCredentials(emailOrPhoneNumber: $emailOrPhoneNumber) {
      twoFactor
      isVerified
    }
  }
`
export const SignInDocument = gql`
  mutation signIn(
    $emailOrPhoneNumber: String!
    $password: String!
    $token: String
  ) {
    signIn(
      emailOrPhoneNumber: $emailOrPhoneNumber
      password: $password
      token: $token
    )
  }
`
export const SignUpDocument = gql`
  mutation signUp(
    $email: String!
    $password: String!
    $fullName: String!
    $phoneNumber: String!
    $telegramId: String!
    $refererId: Int
    $callbackUrl: String
  ) {
    signUp(
      email: $email
      password: $password
      fullName: $fullName
      telegramId: $telegramId
      phoneNumber: $phoneNumber
      refererId: $refererId
      callbackUrl: $callbackUrl
    ) {
      id
    }
  }
`
export const SendVerificationDocument = gql`
  mutation sendVerification($email: String!, $callbackUrl: String!) {
    sendVerification(email: $email, callbackUrl: $callbackUrl)
  }
`
export const VerifyEmailDocument = gql`
  mutation verifyEmail($verificationToken: String!) {
    verifyEmail(verificationToken: $verificationToken)
  }
`
export const TicketsDocument = gql`
  query tickets($id: Int!) {
    tickets(id: $id) {
      ...Tickets
    }
  }
  ${TicketsFragmentDoc}
`
export const UserTransactionsDocument = gql`
  query userTransactions(
    $after: TransactionWhereUniqueInput
    $before: TransactionWhereUniqueInput
    $first: Int
    $last: Int
    $orderBy: [TransactionOrderByWithRelationInput!]
    $where: TransactionWhereInput
  ) {
    user {
      transactions(
        after: $after
        before: $before
        first: $first
        last: $last
        orderBy: $orderBy
        where: $where
      ) {
        ...Transaction
      }
    }
  }
  ${TransactionFragmentDoc}
`
export const TransactionsDocument = gql`
  query transactions(
    $after: TransactionWhereUniqueInput
    $before: TransactionWhereUniqueInput
    $first: Int
    $last: Int
    $orderBy: [TransactionOrderByWithRelationInput!]
    $where: TransactionWhereInput
  ) {
    transactions(
      after: $after
      before: $before
      first: $first
      last: $last
      orderBy: $orderBy
      where: $where
    ) {
      ...Transaction
    }
  }
  ${TransactionFragmentDoc}
`
export const GetTransactionsDocument = gql`
  mutation getTransactions($kind: String, $skip: Int!, $take: Int!) {
    getTransaction(kind: $kind, skip: $skip, take: $take) {
      ...Transaction
    }
  }
  ${TransactionFragmentDoc}
`
export const TransferDocument = gql`
  mutation transfer($from: Balance!, $to: Balance!, $amount: Float!) {
    transfer(from: $from, to: $to, amount: $amount) {
      ...Transaction
    }
  }
  ${TransactionFragmentDoc}
`
export const WithdrawCodeRequestDocument = gql`
  mutation withdrawCodeRequest($code: Int!, $email: String!) {
    withdrawCodeRequest(code: $code, email: $email)
  }
`
export const WithdrawRequestDocument = gql`
  mutation withdrawRequest($amount: Float!, $to: String!, $token: String) {
    withdrawRequest(amount: $amount, to: $to, token: $token) {
      ...Transaction
    }
  }
  ${TransactionFragmentDoc}
`
export const WithdrawBtcRequestDocument = gql`
  mutation withdrawBTCRequest(
    $amount: Float!
    $to: String!
    $token: String
    $amountInBTC: Float!
  ) {
    withdrawBTCRequest(
      amount: $amount
      to: $to
      token: $token
      amountInBTC: $amountInBTC
    ) {
      ...Transaction
    }
  }
  ${TransactionFragmentDoc}
`
export const BtcDepositDocument = gql`
  mutation btcDeposit(
    $amount: Float!
    $btcWallet: String!
    $amountInBTC: Float!
    $userId: Int!
  ) {
    btcDeposit(
      amount: $amount
      btcWallet: $btcWallet
      amountInBTC: $amountInBTC
      userId: $userId
    ) {
      ...Transaction
    }
  }
  ${TransactionFragmentDoc}
`
export const SetTwoFactorDocument = gql`
  mutation setTwoFactor($enable: Boolean!) {
    setTwoFactor(enable: $enable) {
      ...TwoFactor
    }
  }
  ${TwoFactorFragmentDoc}
`
export const TwoFactorDocument = gql`
  query twoFactor {
    user {
      twoFactor {
        ...TwoFactor
      }
    }
  }
  ${TwoFactorFragmentDoc}
`
export const IamDocument = gql`
  query iam {
    user {
      ...UserInfo
    }
  }
  ${UserInfoFragmentDoc}
`
export const UserDocument = gql`
  query user($id: Int!) {
    user(id: $id) {
      ...UserPublicInfo
    }
  }
  ${UserPublicInfoFragmentDoc}
`
export const ReferrerDocument = gql`
  query referrer($id: Int) {
    user(id: $id) {
      ...UserPublicInfo
    }
  }
  ${UserPublicInfoFragmentDoc}
`
export const ReferralDocument = gql`
  query referral($id: Int) {
    user(id: $id) {
      ...Network
    }
  }
  ${NetworkFragmentDoc}
`
export const UpdateDocument = gql`
  mutation update($id: Int, $password: String, $oldPassword: String) {
    updateUser(id: $id, password: $password, oldPassword: $oldPassword) {
      ...UserInfo
    }
  }
  ${UserInfoFragmentDoc}
`
export const HideDocument = gql`
  mutation hide($id: Int, $hideData: Boolean!) {
    hideData(id: $id, hideData: $hideData) {
      ...UserInfo
    }
  }
  ${UserInfoFragmentDoc}
`
export const AssignUsdtWalletDocument = gql`
  mutation assignUSDTWallet($usdtWallet: String!) {
    assignUSDTWallet(usdtWallet: $usdtWallet) {
      ...UserInfo
    }
  }
  ${UserInfoFragmentDoc}
`
export const DeleteUserPromoDocument = gql`
  mutation deleteUserPromo($id: Int!) {
    deleteUserPromo(id: $id) {
      ...UserInfo
    }
  }
  ${UserInfoFragmentDoc}
`
export const DepositDocument = gql`
  mutation deposit {
    deposit
  }
`

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string
) => Promise<T>

const defaultWrapper: SdkFunctionWrapper = (
  action,
  _operationName,
  _operationType
) => action()

export function getSdk(
  client: GraphQLClient,
  withWrapper: SdkFunctionWrapper = defaultWrapper
) {
  return {
    dashboard(
      variables?: DashboardQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<DashboardQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DashboardQuery>(DashboardDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'dashboard',
        'query'
      )
    },
    promocodes(
      variables?: PromocodesQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<PromocodesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<PromocodesQuery>(PromocodesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'promocodes',
        'query'
      )
    },
    purchase(
      variables: PurchaseMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<PurchaseMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<PurchaseMutation>(PurchaseDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'purchase',
        'mutation'
      )
    },
    consumePromocode(
      variables: ConsumePromocodeMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<ConsumePromocodeMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ConsumePromocodeMutation>(
            ConsumePromocodeDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'consumePromocode',
        'mutation'
      )
    },
    resetPassword(
      variables: ResetPasswordMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<ResetPasswordMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ResetPasswordMutation>(
            ResetPasswordDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'resetPassword',
        'mutation'
      )
    },
    setPassword(
      variables: SetPasswordMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<SetPasswordMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SetPasswordMutation>(SetPasswordDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'setPassword',
        'mutation'
      )
    },
    checkCredentials(
      variables: CheckCredentialsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<CheckCredentialsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CheckCredentialsQuery>(
            CheckCredentialsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'checkCredentials',
        'query'
      )
    },
    signIn(
      variables: SignInMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<SignInMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SignInMutation>(SignInDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'signIn',
        'mutation'
      )
    },
    signUp(
      variables: SignUpMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<SignUpMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SignUpMutation>(SignUpDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'signUp',
        'mutation'
      )
    },
    sendVerification(
      variables: SendVerificationMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<SendVerificationMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SendVerificationMutation>(
            SendVerificationDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'sendVerification',
        'mutation'
      )
    },
    verifyEmail(
      variables: VerifyEmailMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<VerifyEmailMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<VerifyEmailMutation>(VerifyEmailDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'verifyEmail',
        'mutation'
      )
    },
    tickets(
      variables: TicketsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<TicketsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<TicketsQuery>(TicketsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'tickets',
        'query'
      )
    },
    userTransactions(
      variables?: UserTransactionsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<UserTransactionsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UserTransactionsQuery>(
            UserTransactionsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'userTransactions',
        'query'
      )
    },
    transactions(
      variables?: TransactionsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<TransactionsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<TransactionsQuery>(TransactionsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'transactions',
        'query'
      )
    },
    getTransactions(
      variables: GetTransactionsMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<GetTransactionsMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetTransactionsMutation>(
            GetTransactionsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'getTransactions',
        'mutation'
      )
    },
    transfer(
      variables: TransferMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<TransferMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<TransferMutation>(TransferDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'transfer',
        'mutation'
      )
    },
    withdrawCodeRequest(
      variables: WithdrawCodeRequestMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<WithdrawCodeRequestMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<WithdrawCodeRequestMutation>(
            WithdrawCodeRequestDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'withdrawCodeRequest',
        'mutation'
      )
    },
    withdrawRequest(
      variables: WithdrawRequestMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<WithdrawRequestMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<WithdrawRequestMutation>(
            WithdrawRequestDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'withdrawRequest',
        'mutation'
      )
    },
    withdrawBTCRequest(
      variables: WithdrawBtcRequestMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<WithdrawBtcRequestMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<WithdrawBtcRequestMutation>(
            WithdrawBtcRequestDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'withdrawBTCRequest',
        'mutation'
      )
    },
    btcDeposit(
      variables: BtcDepositMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<BtcDepositMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<BtcDepositMutation>(BtcDepositDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'btcDeposit',
        'mutation'
      )
    },
    setTwoFactor(
      variables: SetTwoFactorMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<SetTwoFactorMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SetTwoFactorMutation>(
            SetTwoFactorDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'setTwoFactor',
        'mutation'
      )
    },
    twoFactor(
      variables?: TwoFactorQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<TwoFactorQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<TwoFactorQuery>(TwoFactorDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'twoFactor',
        'query'
      )
    },
    iam(
      variables?: IamQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<IamQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IamQuery>(IamDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'iam',
        'query'
      )
    },
    user(
      variables: UserQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<UserQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UserQuery>(UserDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'user',
        'query'
      )
    },
    referrer(
      variables?: ReferrerQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<ReferrerQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ReferrerQuery>(ReferrerDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'referrer',
        'query'
      )
    },
    referral(
      variables?: ReferralQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<ReferralQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ReferralQuery>(ReferralDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'referral',
        'query'
      )
    },
    update(
      variables?: UpdateMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<UpdateMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateMutation>(UpdateDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'update',
        'mutation'
      )
    },
    hide(
      variables: HideMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<HideMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<HideMutation>(HideDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'hide',
        'mutation'
      )
    },
    assignUSDTWallet(
      variables: AssignUsdtWalletMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<AssignUsdtWalletMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AssignUsdtWalletMutation>(
            AssignUsdtWalletDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'assignUSDTWallet',
        'mutation'
      )
    },
    deleteUserPromo(
      variables: DeleteUserPromoMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<DeleteUserPromoMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DeleteUserPromoMutation>(
            DeleteUserPromoDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'deleteUserPromo',
        'mutation'
      )
    },
    deposit(
      variables?: DepositMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<DepositMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DepositMutation>(DepositDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'deposit',
        'mutation'
      )
    },
  }
}
export type Sdk = ReturnType<typeof getSdk>
