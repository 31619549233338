import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import MainLayout from '../layouts/MainLayout.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/cabinet'
  },
  {
    path: '/auth',
    component: () => import('../pages/Auth.vue')
  },
  {
    path: '/restore',
    component: () => import('../pages/RestorePassword.vue')
  },
  {
    path: '/privacy/ru',
    component: () => import('../pages/PrivacyRu.vue')
  },
  {
    path: '/privacy/en',
    component: () => import('../pages/PrivacyEn.vue')
  },
  {
    path: '/verify',
    name: 'verify',
    component: () =>
      import(/* webpackChunkName: "verify" */ '../pages/Verify.vue')
  },
  {
    path: '/cabinet',
    component: MainLayout,
    children: [
      {
        path: '',
        name: 'dashboard',
        component: () =>
          import(/* webpackChunkName: "about" */ '../pages/Dashboard.vue')
      },
      {
        path: '/cabinet/statistics',
        name: 'statistics',
        component: () =>
          import(/* webpackChunkName: "about" */ '../pages/Statistic.vue')
      },
      {
        path: '/cabinet/plans',
        name: 'plans',
        component: () =>
          import(/* webpackChunkName: "about" */ '../pages/Plans.vue')
      },
      {
        path: '/cabinet/profile',
        name: 'profile',
        component: () =>
          import(/* webpackChunkName: "about" */ '../pages/Profile.vue')
      },
      {
        path: '/cabinet/promocodes',
        name: 'promocodes',
        component: () =>
          import(/* webpackChunkName: "about" */ '../pages/PromoCode.vue')
      },
      {
        path: '/cabinet/careers',
        name: 'test',
        component: () =>
          import(/* webpackChunkName: "about" */ '../pages/Sidebar.vue')
      },
      {
        path: '/cabinet/wallet',
        name: 'wallet',
        component: () =>
          import(/* webpackChunkName: "about" */ '../pages/Wallet.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (
    !localStorage.getItem('token') &&
    !['/auth', '/verify', '/restore', '/privacy/ru', '/privacy/en'].includes(
      to.path
    )
  ) {
    return next({ path: '/auth' })
  }

  return next()
})

export default router
